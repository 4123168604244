









































































































































































































import Vue from 'vue';
// import FormSwitch from '@/components/form/form-switch.vue';
import { onCurrencyFocus, recursiveFillData } from '@/helper/functions';
import { exportToPDF } from '@/helper/handle-pdf';
import { dataChangeMixin } from '@/mixins/dataChangeMixin';

export default Vue.extend({
  name: 'biogas-insurance-basic',
  mixins: [dataChangeMixin],
  data() {
    return {
      name: 'biogas-insurance-basic',
      key: 'BIOGAS_INSURANCE',
      versicherungsort: {
        Strabe: '',
        PLZ: '',
        Ort: '',
        ZursZone: '',
        Naturgefahrenklasse: '',
        Geltungsbereich: '',
        IndividuelleVereinbarungenZumGeltungsbereich: '',
      },
      anzahlDerAnlagen: 1,
      ArtDerAnlage: null,
      AnzahlDerMotorenJeAnlage: 1,
      versicherungssummeJeMotor: null,
      versicherungssummeJeAnlage: null,
      versicherungssummeFermenterbiologieJeAnlage: null,
      SBMaschine: null,
      SBAnlage: null,
      SBFermenterbiologie: null,
    };
  },
  props: ['insuranceKey', 'caseStatus', 'insuranceData', 'insuranceFormsApiData'],
  watch: {
    insuranceFormsApiData() {
      if (this.insuranceData === null) {
        // console.log(this.insuranceData);
        this.fillApiValuesData();
      }
    },
  },
  methods: {
    onCurrencyFocus,
    fillApiValuesData() {
      if (this.insuranceFormsApiData && this.insuranceFormsApiData.basicData) {
        recursiveFillData(this.versicherungsort, this.insuranceFormsApiData.basicData.versicherungsort);
        this.AnzahlDerMotorenJeAnlage = this.insuranceFormsApiData.basicData.AnzahlDerMotorenJeAnlage;
        this.ArtDerAnlage = this.insuranceFormsApiData.basicData.ArtDerAnlage;
        this.versicherungssummeJeMotor = this.insuranceFormsApiData.basicData.versicherungssummeJeMotor;
        this.versicherungssummeJeAnlage = this.insuranceFormsApiData.basicData.versicherungssummeJeAnlage;
        this.versicherungssummeFermenterbiologieJeAnlage = this.insuranceFormsApiData.basicData.versicherungssummeFermenterbiologieJeAnlage;
        this.SBMaschine = this.insuranceFormsApiData.basicData.SBMaschine;
        this.SBAnlage = this.insuranceFormsApiData.basicData.SBAnlage;
        this.SBFermenterbiologie = this.insuranceFormsApiData.basicData.SBFermenterbiologie;
      }
    },
    setValues(basicData: any) {
      this.versicherungsort = basicData.versicherungsort;
      this.anzahlDerAnlagen = basicData.anzahlDerAnlagen;
      this.ArtDerAnlage = basicData.ArtDerAnlage;
      this.AnzahlDerMotorenJeAnlage = basicData.AnzahlDerMotorenJeAnlage;
      this.versicherungssummeJeMotor = basicData.versicherungssummeJeMotor;
      this.versicherungssummeJeAnlage = basicData.versicherungssummeJeAnlage;
      this.versicherungssummeFermenterbiologieJeAnlage = basicData.versicherungssummeFermenterbiologieJeAnlage;
      this.SBMaschine = basicData.SBMaschine;
      this.SBAnlage = basicData.SBAnlage;
      this.SBFermenterbiologie = basicData.SBFermenterbiologie;
    },
    onSubmit(goNextPage = true) {
      const data = {
        versicherungsort: this.versicherungsort,
        anzahlDerAnlagen: this.anzahlDerAnlagen,
        ArtDerAnlage: this.ArtDerAnlage,
        AnzahlDerMotorenJeAnlage: this.AnzahlDerMotorenJeAnlage,
        versicherungssummeJeMotor: this.versicherungssummeJeMotor,
        versicherungssummeJeAnlage: this.versicherungssummeJeAnlage,
        versicherungssummeFermenterbiologieJeAnlage: this.versicherungssummeFermenterbiologieJeAnlage,
        SBMaschine: this.SBMaschine,
        SBAnlage: this.SBAnlage,
        SBFermenterbiologie: this.SBFermenterbiologie,
      };
      if (goNextPage) {
        this.$emit('basic-data-change', data, this.key);
      } else {
        this.$emit('basic-data-change', data, this.key, 1);
      }
    },
    downloadPDF() {
      exportToPDF.call(this, 'Zusammenfassung-Biogas-Grunddaten.pdf');
    },
    savePdf() {
      // save insurance, get id and make pdf
      this.$emit('add-to-queue', 'saveBasicPDF', '');
      this.onSubmit(false);
    },
  },
  mounted() {
    (this as any).resetWatcherActivated();
    this.$emit('on-key-change', this.key);

    if (this.insuranceData && this.insuranceData.basicData) {
      this.setValues(this.insuranceData.basicData);
    }
    (this as any).startWatcherActivated();
  },
  computed: {
  },
});
